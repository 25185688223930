<template>
    <div>
        <div class="main-box">
            <div class="main">
                <div class="title">请选择店铺类型</div>
                <div class="navs">
                    <div class="user" @click="goPersonShop">
                        <img class="user-icon" src="../../assets/images/login/user.png" alt="">
                        <div>
                            <div>我是个人商家</div>
                        </div>
                        <i style="font-size:30px;color:#666" class="el-icon-arrow-right"></i>
                    </div>
                    <div class="user" @click="goEnterShop">
                        <img class="user-icon" src="../../assets/images/login/qiye.png" alt="">
                        <div>
                            <div>我是企业商家</div>
                        </div>
                        <i style="font-size:30px;color:#666" class="el-icon-arrow-right"></i>
                    </div>
                </div>
            </div>
        </div>
        <!-- 客服 -->
        <div class="chat">
            <img src="../../assets/images/home/chat.png" alt="">
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
			shop:[]
        };
    },

    mounted() {
		 this.getShop()
    },

    methods: {
		getShop() {
		    this.$util.post('/shop/myShop').then(res => {
		        this.shop = res.data
		    })
		},
        goPersonShop() {
            this.$router.push({ path: '/personShop', query: { num: 0 } })
        },
        goEnterShop() {
            this.$router.push({ path: '/enterShop', query: { num: 0 } })
        }
    },
};
</script>

<style lang="scss" scoped>
.main-box {
    background: #f6f6f6;
    padding: 20px 0 100px;
}
.main {
    width: 1200px;
    height: 700px;
    margin: 0 auto;
    background: #fff;
    padding: 30px;
}
.title {
    padding: 10px 0 30px;
    border-bottom: 1px solid #dbdbdb;
    font-size: 22px;
    color: #222;
    margin-bottom: 30px;
}
.navs {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.user {
    cursor: pointer;
    background: #fffafa;
    border: 1px solid #e40012;
    font-weight: bold;
    padding: 0 30px;
    width: 430px;
    height: 122px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 22px;
    color: #333333;
    margin-top: 30px;
    span {
        font-size: 14px;
        color: #666666;
        margin-top: 10px;
    }
}
.user-icon {
    width: 43px;
    height: 49px;
}
.chat {
    width: 97px;
    height: 103px;
    position: fixed;
    right: 0;
    z-index: 10000;
    top: 40%;
    img {
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
}
</style>